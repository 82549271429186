import React from "react";
import imgCarousel01 from "../assets/vm72.webp";
import imgCarousel02 from "../assets/vm82.webp";
import imgCarousel03 from "../assets/vm88.webp";
import "../styles/Carousel.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cube";
import "swiper/css/pagination";
import "swiper/css/navigation";
import {Keyboard, Pagination, Navigation, Autoplay } from "swiper";
import aulaIcon from '../assets/aula.png'
import searchIcon from '../assets/busqueda.png'
import statsIcon from '../assets/estadisticas.png'




export default function Carousel() {
  const arrayCarousel = [
    {
      id: "001",
      src: imgCarousel01,
      alt: "img carousel",
      icon:aulaIcon,
      title:"Iniciar clase",
      text:"Realiza, desarrolla y registra tus clases con toda la información necesaria de manera eficaz y dinámica."
    },
    {
        id: "002",
        src: imgCarousel02,
        alt: "img carousel",
        icon:searchIcon,
        title:"Recursos",
        text:"Visualiza actividades sugeridas y recursos pedagógicos de todos los objetivos de aprendizajes."
      },
      {
        id: "001",
        src: imgCarousel03,
        alt: "img carousel",
        icon:statsIcon,
        title:"Estadísticas",
        text:"Observa y analiza de forma eficiente  los avances de tus alumnos en cuanto a los parámetros físicos, académicos y de bienestar."
      },
  ];

  return (
    <div className="container div-carousel my-5">
      <Swiper
      loop={true}
      navigation={true}
        effect={"cube"}
        keyboard={{
            enabled: true,
          }}
          pagination={{
            clickable: true,
          }}
        // cubeEffect={{
        //   shadowOffset: 20,
        //   shadowScale: 0.94,
        // }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        grabCursor={true}
        modules={[Keyboard, Pagination, Navigation, Autoplay]}
        className="mySwiper"
      >
        {
            arrayCarousel.map((card)=>{
                return (
                    <SwiperSlide key={card.id} className="swipper-slide-card" >
                    <div className="div-icon-carousel p-3 bg-body rounded">
                      <div className="div-info shadow-lg p-3 bg-body rounded">
                        <img className="img-logo-app" src={card.icon} alt="icono perfil" />
                        <div className="div-text-vm-app">
                          <h2>{card.title}</h2>
                          <p> {card.text} </p>
                        </div>
                      </div>
                      <img
                        src={card.src}
                        alt="img carousel"
                        className="img-carousel"
                      />
                    </div>
                  </SwiperSlide>
                )
            })
        }
      </Swiper>
    </div>
  );
}
