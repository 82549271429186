import React from "react";
import "../styles/Searchview.css";
import titleVM from "../assets/tittleVM.svg";
// import Tilt from "react-vanilla-tilt";
import homeApp from '../assets/homeApp.webp'

export default function SearchView() {
  return (
    <div className="bg-home-app">
      <div className="container div-container-home-app">


            <img
            src={homeApp}
            alt="home app VM"
            className="img-home-app"
            id="home-app"
            max="10"
            speed="150"
          />



        <div
          className="div-tittle-home shadow p-5 bg-body"
          data-aos="zoom-in"
          data-aos-offset="200"
          data-aos-delay="70"
          data-aos-duration="1000"
          data-aos-mirror="true"
          data-aos-once="true"
        >
          <img src={titleVM} alt="tittle-vm-logo" />
          <h2>
            Entrena, crea y publica tus momentos <strong>VitalMove</strong>{" "}
          </h2>
        </div>
      </div>
    </div>
  );
}
