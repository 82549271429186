import React from "react";
import textLogo from "../assets/tittleVM.svg";
import "../styles/Vitalmove.css";
import AOS from "aos";
import "aos/dist/aos.css";

export default function VitalMove() {
  AOS.init();
  return (
    <div className="div-vital-move-app">
      <div
        className="div-text-vital-move "
        data-aos="zoom-out"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="1000"
        data-aos-mirror="true"
        data-aos-once="true"
      >
        <div className="div-total-move shadow p-3 bg-body rounded div-text-vital-move-app py-5">
          <h2 className="h2-title-vital">
            ¿ Qué es{" "}
            <img
              className="text-logo-vital-move"
              src={textLogo}
              alt="text logo"
            />
            ?
          </h2>
          <p className="pt-3">
            Es un programa educativo de{" "}
            <strong>actividad física, bienestar y salud</strong>, conformado por
            un equipo multidisciplinario que utilizan soluciones tecnológicas
            para mejorar la calidad de vida de sus usuarios tanto en el ámbito
            publico como privado.
          </p>
        </div>
      </div>

      <div className="div-img-vital-move-app">
        {/* <img className="img-vital-move" src={appGlobal} alt="vital-move-app" /> */}
      </div>
    </div>
  );
}
