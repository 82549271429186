import React from 'react'
import '../styles/Banner.css'

export default function Banner() {
  return (
    <div className='div-banner'>
        <div className='banner-01' ></div>
        <div className='banner-02' ></div>
    </div>
  )
}
