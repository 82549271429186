import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const emailAPI = createApi({
    reducerPath: "emailAPI",

    baseQuery: fetchBaseQuery({
        baseUrl: "https://vm-api-newsletter.onrender.com"
    }),
    tagTypes: ['Post'],

    endpoints: (builder) => ({
        createEmail: builder.mutation({
            query: (email) =>({
                url:'/newsletter/create',
                method: 'POST',
                body: email,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            })
        })
    })
})

export default emailAPI;
export const {useCreateEmailMutation} = emailAPI;