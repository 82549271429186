import React from "react";
import AppGlobal from "../components/AppGlobal";
import Banner from "../components/Banner";
import Carousel from "../components/Carousel";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import SearchView from "../components/SearchView";
import Store from "../components/Store";
import VitalMove from "../components/VitalMove";
import VmApp from "../components/VmApp";

export default function Welcome() {
  return (
    <>
      <NavBar />
      <VitalMove />
      <AppGlobal />
      <VmApp />
      <SearchView />
      <Carousel />
      <Banner />
      <Store />
      <Footer />
    </>
  );
}
