import React from "react";
import "../styles/Appglobal.css";
import handPhone from "../assets/telefono-movil.png";
import AOS from "aos";
import "aos/dist/aos.css";

export default function AppGlobal() {
  AOS.init();
  return (
    <div className="div-vital-move-app div-vm-second-info">
      <div className="div-img-vital-move">
        {/* <img className="img-vital-move" src={appGlobal} alt="vital-move-app" /> */}
      </div>

      <div
        className="div-text-vital-move"
        data-aos="zoom-in"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="1000"
        data-aos-mirror="true"
        data-aos-once="true"
      >
        <div className="div-total-move shadow p-3 bg-body rounded">
          <img src={handPhone} alt="hand phone icon" className="hand-phone" />
          <h2>Descarga y crea tu cuenta</h2>
          <p>
            Ingresa a la AppStore o PlayStore y descarga{" "}
            <strong>VitalMove</strong>, crea tu cuenta o ingresa con tus redes
            sociales favoritas y crea tu mejor versión con todas nuestras
            herramientas.{" "}
          </p>
        </div>
      </div>
    </div>
  );
}
