import React from "react";
import "../styles/Footer.css";
import logoVM from "../assets/logoVM02.svg";
import facebookIcon from "../assets/facebookIcon.png";
import instagramIcon from "../assets/instagramIcon.png";
// import twitterIcon from "../assets/twiterIcon.png";
import swal from 'sweetalert2'
import { useCreateEmailMutation } from "../features/emailAPI";
import { useRef } from "react";
import { Link } from "react-router-dom";


export default function Footer() {

  const emailSuscriber = useRef();
  const [addNewEmail] = useCreateEmailMutation();

  const handleSubmit = (e) => {
    e.preventDefault();

    let newEmail = {
      email: emailSuscriber.current.value,
    }

    addNewEmail(newEmail).then((res)=>{
      if (res.error){
        let dataError = res.error
        let dataMessage = dataError.data
        swal.fire({
          title: "Error!",
          text: dataMessage.message,
          icon: "error",
        });

        } else {

          let dataResponse = res.data
          let dataSuccess = dataResponse.message
          swal.fire({
            title: "Success! ",
            text: dataSuccess,
            icon: "success",
          });
          let inputForm = document.querySelector("#form-new-users");
          inputForm.reset();

        }
    }).catch((error) =>{
      console.log(error)
    })
  }


  return (
    <div className="div-footer">
      <div className="container div-footer-container pt-3 ">
        <div className="div-logo-social">
          <img className="logo-vm" src={logoVM} alt="Vital Move Logo" />
        </div>

    <form id="form-new-users">
        <div className="div-suscribe">
          <div className="div-form">
            <label forHtml="exampleFormControlInput1" className="form-label">
              Suscríbete a nuestro boletín
            </label>
            <input
              type="email"
              className="form-control"
              placeholder="nombre@email.com"
              required
              name="exampleFormControlInput1"
              ref={emailSuscriber}
            />
          </div>
          <div className="div-form">
          <button className="btn-login" onClick={handleSubmit} >Suscribirse</button>
          </div>
        </div>
        </form>
      </div>

      <div className="div-container-copyright py-2">
        <p>© VitalMove 2022 All right reserved.</p>

        <Link to={`/policies`} className="text-decoration-none text-light enlarge-on-hover " >
          <p>Política de privacidad</p>
        </Link>

        <Link to={`/conditions`} className="text-decoration-none text-light enlarge-on-hover " >
          <p>Términos y Condiciones</p>
        </Link>
        
        <div className="div-social-icons">
          <a href="https://www.facebook.com/VitalMove.Oficial" target="_blank" rel="noreferrer">
            <img src={facebookIcon} alt="icon-social" className="icon-social" />
          </a>
          <a href="https://www.instagram.com/vitalmove.oficial" target="_blank" rel="noreferrer">
            <img
              src={instagramIcon}
              alt="icon-social"
              className="icon-social"
            />
          </a>
          {/* <a href="https://twitter.com" target="_blank" rel="noreferrer">
            <img src={twitterIcon} alt="icon-social" className="icon-social" />
          </a> */}
        </div>
      </div>
    </div>
  );
}
