import React from 'react'
import ReactLoading from "react-loading";
import '../styles/Loading.css'
import logoVM from '../assets/logoVM03.svg'
export default function Loading() {

  return (
    <div className='div-loading'>
        <img src={logoVM} alt="logo Vital Move" className='logo-vm-loading'  />
        <ReactLoading type="spinningBubbles"  className="loading-item" />
    </div>
  )
}
