import React from 'react'
import '../styles/Navbar.css'
import appStoreLogo from '../assets/app-store.png'
import playStoreLogo from '../assets/Androidpng.png'
import imgAppApple from '../assets/iphoneApp08.webp'
import logoVM from '../assets/logoVM02.svg'
import logoBlack from '../assets/logoVM03.svg'
import imgAppAppleBlack from '../assets/vmIphoneResponsive.png'

export default function NavBar() {
  return (
    <>
    <header className='div-header'>
        <nav className='div-navbar px-5'>
            <img className='logo-vm logo-light' src={logoVM} alt="Vital Move Logo" />
            <img className='logo-vm logo-black' src={logoBlack} alt="Vital Move Logo" />
            {/* <div className='div-actions'>
                <a href="">Nuestra App</a>
                <a href="">Mision</a>
                <a href="">Vision</a>
                <a href="">Entrenamiento</a>
            </div> */}
            {/* <a className='btn-login' href="#">INGRESAR</a> */}
        </nav>
        <div className='div-hero-app'>

            <div className='div-hero-text rounded shadow animate__animated  animate__flipInY'>
                <img src={logoBlack} alt="logo-black-VM" className='logo-black-vm' />
                <p>Bienvenido a tu guía virtual de entrenamiento, nutrición y bienestar. Diseñada para cumplir tus objetivos de forma transversal sin importar edad y lugar.</p>
                <p className='text-center'><i>Ya disponible en Android!</i></p>
                <div className='div-logo-stores' >
                    <img className='logo-stores' src={appStoreLogo} alt="app Store Logo" />

                    <a rel="noreferrer" target="_blank" href="https://play.google.com/store/apps/details?id=com.vm_mobile&pcampaignid=web_share">
                    <img className='logo-stores' src={playStoreLogo} alt="play Store Logo" />
                    </a>

                </div>
            </div>
            <img className='img-android-app animate__animated animate__fadeInDown' src={imgAppApple} alt="img app android" />
            <img className='img-android-app-responsive animate__animated animate__fadeInDown' src={imgAppAppleBlack} alt="img app android" />
            
        </div>
    </header>
    </>


  )
}
