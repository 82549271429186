import React from 'react'

function Conditions() {
  return (
    <>
      <h1>Términos y condiciones</h1>
      <p>Ultima actualización: Noviembre 08 de 2023</p>
      <h2 className='text-bold ml-4'>Términos y condiciones</h2>
      <p>
        El Usuario acepta expresamente los Términos y Condiciones,
        siendo condición esencial para la utilizacion de la
        aplicación. En el evento en que se encuentre en desacuerdo con
        estos Términos y Condiciones, solicitamos abandonar la
        aplicación inmediatamente. Vital Move podrá modificar los
        presentes Términos y Condiciones, avisando a los usuarios de
        la aplicacion mediante pub
      </p>
      <hr />
      <h2>Juridicción</h2>
      <p>Estos términos y condiciones y todo lo que tenga que ver con
        esta aplicación, se rigen por las leyes de Chile.</p>
      <hr />
      <h2>Uso de información no personal</h2>
      <p>
        Vital Move tambien recolecta Información no personal en forma
        agregada para seguimiento de datos como el número total de
        descargas de la aplicación. Utilizamos esta información, que
        permanece en forma agregada para entender el comportamiento de
        la aplicación.
      </p>
      <hr />
      <h2>Seguridad</h2>
      <p>Vital Move está comprometido en la protección de la seguridad de
        su información personal. Vital Move tiene implementados
        mecanismos de seguridad que aseguran la protección de la
        información personal, asi como los accesos únicamente al
        personal y sistemas autorizados también contra la pérdida, uso
        indebido y alteración de sus datos de usuario bajo nuestro
        control.</p>
      <p>Excepto como se indica a continuación, sólo personal autorizado
        tiene acceso a la información que nos proporciona. Además, hemos
        impuesto reglas estructas a los empleados de Skandia con acceso
        a las bases de datos que almacenan información del usuario o a
        los servidores que hospedan nuestros servicios.</p>
      <hr />
      <h2>Licencia para copiar para uso personal</h2>
      <p>Usted podrá leer, visualizar, imprimir y descargar el material
        de sus productos.

        Ninguna parte de la aplicación podrá ser reproducida o
        transmitida o almacenada en otro sitio web o en otra forma de
        sistema de recuperación electrónico.
        Ya sea que se reconozca especificamente o no, las marcas
        comerciales, las marcas de servicio y los logos visualizados en
        esta aplicación pertenecen al grupo de compañías Vital Move.</p>
      <hr/>
      <h2>Contacto</h2>
      <ul>
        <li>
          <p>Email: info@vitalmove.cl</p>
        </li>
        <li>
          <p>
            Visita nuestra pagina web:{" "}
            <a
              href="https://www.vitalmove.cl"
              rel="external nofollow noopener noreferrer"
              target="_blank"
            >
              www.vitalmove.cl
            </a>
          </p>
        </li>
      </ul>
    </>
  )
}

export default Conditions
