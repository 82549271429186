import React from "react";
import ScrollToTopOnRender from "../components/ScrollToTopOnRender";

const Privacity = () => {
  return (
    <>
      <ScrollToTopOnRender />
      <h1>Política de privacidad</h1>
      <p>Última actualización: 02 de julio, 2024</p>
      <p>
        Nuestra aplicación, VitalMove, valora su privacidad y se compromete a
        proteger su información personal. Esta política de privacidad describe
        cómo recopilamos, utilizamos y protegemos su información.
      </p>
      <hr />
      <h2>Interpretación y definiciones</h2>
      <h3>Interpretación</h3>
      <p>
        Las palabras cuya letra inicial va en mayúscula tienen significados
        definidos en las condiciones siguientes. Las definiciones siguientes
        tendrán el mismo significado independientemente de que aparezcan en
        singular o en plural.
      </p>
      <hr />
      <h3>Definiciones</h3>
      <p>A efectos de la presente Política de Privacidad:</p>
      <ul>
        <li>
          <p>
            <strong>Cuenta</strong> significa una cuenta única creada para que
            Usted acceder a nuestro Servicio o a partes de nuestro Servicio.
          </p>
        </li>
        <li>
          <p>
            <strong>Afiliado</strong> significa una entidad que controla, es
            controla o está bajo control común con una parte, cuando
            &quot;control&quot; significa la propiedad del 50% o más de las
            acciones, participaciones u otros valores con derecho a voto para la
            elección de directores u otra autoridad de gestión.
          </p>
        </li>
        <li>
          <p>
            <strong>Aplicación</strong> se refiere a VitalMove, el programa
            proporcionado por la empresa.
          </p>
        </li>
        <li>
          <p>
            <strong>Empresa</strong> (denominado &quot;la Empresa&quot;,
            &quot;Nosotros&quot;, &quot;Nosotros&quot; o &quot;Nuestra&quot; en
            este Acuerdo) se refiere a VitalMove, Santiago de Chile.
          </p>
        </li>
        <li>
          <p>
            <strong>País</strong> se refiere a: Chile
          </p>
        </li>
        <li>
          <p>
            <strong>Dispositivo</strong> cualquier dispositivo que pueda acceder
            al Servicio como un ordenador, un teléfono móvil o una tableta
            digital.
          </p>
        </li>
        <li>
          <p>
            <strong>Datos personales</strong> es toda información relativa a una
            persona persona identificada o identificable.
          </p>
        </li>
        <li>
          <p>
            <strong>Servicio</strong> se refiere a la Aplicación.
          </p>
        </li>
        <li>
          <p>
            <strong>Proveedor de servicios</strong> toda persona física o
            jurídica que trate los datos por cuenta de la Empresa. Se refiere a
            terceras empresas o personas contratadas por la Empresa para
            facilitar el Servicio, prestar el Servicio en nombre de la Empresa,
            para realizar servicios relacionados con el Servicio o para ayudar a
            la Empresa en el análisis de cómo se utiliza el Servicio.
          </p>
        </li>
        <li>
          <p>
            <strong>Datos de uso</strong> se refiere a los datos recogidos
            automáticamente generados por el uso del Servicio o por la propia
            infraestructura del Servicio (por ejemplo, la duración de la visita
            a una página).
          </p>
        </li>
        <li>
          <p>
            <strong>Usted</strong> la persona física que accede o utiliza el
            Servicio, o la empresa u otra entidad jurídica en nombre de la cual
            dicha persona accede o utiliza el Servicio, según proceda.
          </p>
        </li>
      </ul>
      <hr />
      <h2>Recogida y uso de sus datos personales</h2>
      <h3>Tipos de datos recogidos</h3>
      <h4>Datos personales</h4>
      <p>
        Al utilizar nuestro Servicio, es posible que le pidamos que nos
        proporcione determinada información de identificación personal que puede
        ser utilizada para contactar o identificarle. La información de
        identificación personal puede incluir, pero no se limita a a título
        meramente enunciativo:
      </p>
      <ul>
        <li>
          <p>Dirección de correo electrónico.</p>
        </li>
        <li>
          <p>Correo electronico via Google.</p>
        </li>
        <li>
          <p>
            Estatura, Peso corporal, Genero(para calcular el indice de masa
            corporal).
          </p>
        </li>
        <li>
          <p>
            Actividad fisica(Google fit/Smartband/Smartwatch/Dispositivos de
            monitoreo VitalMove).
          </p>
        </li>
        <li>
          <p>Nombre y apellidos.</p>
        </li>
        <li>
          <p>Número de teléfono.</p>
        </li>
        <li>
          <p>Datos de uso.</p>
        </li>
      </ul>
      <hr />
      <h4>Datos de uso</h4>
      <p>
        Los Datos de Uso se recogen automáticamente al utilizar el Servicio.
      </p>
      <p>
        Los Datos de Uso pueden incluir información como la dirección de
        protocolo de Internet Internet (por ejemplo, la dirección IP), el tipo
        de navegador, la versión del navegador, las páginas de nuestro Servicio
        que Usted visita, la hora y la fecha de Su visita el tiempo empleado en
        esas páginas, identificadores únicos de dispositivos y otros datos de
        diagnóstico.
      </p>
      <p>
        Cuando accede al Servicio por o a través de un dispositivo móvil,
        podemos recopilar cierta información automáticamente, incluyendo, entre
        otros el tipo de dispositivo móvil que utiliza, el identificador único
        de su dispositivo móvil, la IP de su dispositivo móvil, su sistema
        operativo móvil, el tipo de navegador de Internet navegador de Internet
        móvil que utiliza, identificadores únicos de dispositivo y otros datos
        de diagnóstico.
      </p>
      <p>
        También podemos recopilar información que su navegador envíe cada vez
        que Usted visite nuestro Servicio o cuando acceda al Servicio a través
        de un dispositivo móvil. dispositivo móvil.
      </p>
      <hr />
      <h4>Política de datos de usuario de los servicios API de Google</h4>
      <p>
        En VitalMove, cumplimos rigurosamente con la Política de datos de
        usuario de los servicios API de Google, garantizando el respeto a los
        requisitos de uso limitado.
      </p>
      <p>
        El uso de la aplicación y la transferencia de información recibida de
        las API de Google se adherirán a dicha política.{" "}
      </p>
      <p>
        Confirmamos que en VitalMove no realizaremos divulgación de los datos."
        Para más detalles ingrese a{" "}
        <a
          href="https://developers.google.com/terms/api-services-user-data-policy"
          rel="external nofollow noopener noreferrer"
          target="_blank"
        >
          Politica de datos de usuario de los servicios API de Google"{" "}
        </a>{" "}
      </p>
      <hr />
      <h4>Aplicaciones y funciones sociales</h4>
      <p>
        Control Parental: Desde el apartado "Mi Cuenta", los adultos tienen la
        opción de activar el control parental. Este control permite a los
        adultos gestionar las funciones sociales de los usuarios menores de
        edad, incluyendo la posibilidad de habilitar o inhabilitar las funciones
        sociales o seleccionar distintos niveles de funcionalidad.
      </p>
      <hr />
      <h4>Información recopilada durante el uso de la aplicación</h4>
      <p>
        Información de la cuenta: nombre, dirección de correo electrónico y
        contraseña. Información del perfil: foto de perfil, biografía, y otra
        información opcional proporcionada por el usuario. Contenido compartido:
        publicaciones, comentarios, "me gusta" y cualquier otra interacción en
        el feed social. Información técnica: dirección IP, tipo de dispositivo,
        identificadores únicos del dispositivo y datos de uso. Uso de la
        Información Utilizamos su información para:
      </p>
      <ul>
        <li>
          Proporcionar y mejorar nuestros servicios. Personalizar la experiencia
          del usuario. Comunicarnos con los usuarios. Monitorizar y analizar el
          uso de nuestros servicios. Cumplir con las obligaciones legales.
          Seguridad en Internet y Controles Parentales Antes de iniciar sesión,
          es importante que los adultos sean conscientes de que nuestra
          aplicación incluye un feed social donde los usuarios pueden compartir
          publicaciones, dar "me gusta" y comentar. Para proteger la seguridad
          en línea de los menores de edad y recordarles los riesgos asociados
          con las interacciones online, hemos implementado las siguientes
          medidas:
        </li>
      </ul>
      <p>
        Recordatorio de Seguridad: Un recordatorio en la aplicación indica a los
        usuarios que deben tomar medidas para proteger su seguridad en Internet
        y les recuerda los riesgos del mundo real asociados a las interacciones
        online antes de permitir que menores de edad intercambien contenido
        multimedia o información en formato libre. Acción de un Adulto
        Requerida: La aplicación requiere la acción de un adulto para habilitar
        funciones que permitan a los menores de edad intercambiar información
        personal.
      </p>
      <hr />
      <h3>Uso de sus datos personales</h3>
      <p>
        Nuestra empresa se compromete a utilizar sus datos personales de manera
        responsable y en cumplimiento con las políticas de privacidad y los
        requisitos establecidos por los servicios API de Google. A continuación,
        detallamos cómo utilizamos sus datos personales:
      </p>
      <ul>
        <li>
          <p>
            <strong>Prestación y mantenimiento del Servicio:</strong>Utilizamos
            sus datos personales para ofrecerle nuestros servicios y garantizar
            su correcto funcionamiento, incluida la supervisión del uso del
            servicio.
          </p>
        </li>
        <li>
          <p>
            <strong>Gestión de su cuenta: </strong>Utilizamos sus datos
            personales para administrar su registro como usuario del servicio,
            brindarle acceso a las funcionalidades disponibles y gestionar
            cualquier aspecto relacionado con su cuenta.
          </p>
        </li>
        <li>
          <p>
            <strong>Ejecución de contratos: </strong> Utilizamos sus datos
            personales para el desarrollo, cumplimiento y realización de
            contratos, como la compra de productos o servicios a través de
            nuestro servicio.
          </p>
        </li>
        <li>
          <p>
            <strong>Comunicación con usted: </strong> Utilizamos sus datos
            personales para ponernos en contacto con usted a través de
            diferentes medios de comunicación, como correo electrónico, llamadas
            telefónicas o mensajes de texto, para enviarle actualizaciones,
            comunicaciones informativas y noticias relacionadas con el servicio,
            productos o eventos.
          </p>
        </li>
        <li>
          <p>
            <strong>Ofertas y promociones:</strong>Podemos utilizar sus datos
            personales para enviarle noticias, ofertas especiales e información
            sobre productos, servicios y eventos similares a los que ha mostrado
            interés previamente, a menos que decida optar por no recibir dicha
            información.
          </p>
        </li>
        <li>
          <p>
            <strong>Gestión de solicitudes: </strong> Utilizamos sus datos
            personales para atender y gestionar sus solicitudes, garantizando
            una respuesta eficiente y oportuna.
          </p>
        </li>
        <li>
          <p>
            <strong>Transferencias empresariales:</strong> En casos de fusiones,
            adquisiciones o transferencias de activos, podemos utilizar y
            compartir sus datos personales en relación con tales eventos.
          </p>
        </li>
        <li>
          <p>
            <strong>Análisis y mejora del servicio:</strong> Utilizamos sus
            datos personales para realizar análisis de datos, identificar
            tendencias de uso, evaluar la efectividad de nuestras campañas
            promocionales y mejorar continuamente nuestros servicios, productos
            y su experiencia como usuario.
          </p>
        </li>
      </ul>
      <h3>Compartir su información personal.</h3>
      <p>
        No compartimos su información personal con terceros, excepto en los
        siguientes casos:
      </p>
      <ul>
        <li>
          <p>
            Entendemos y respetamos la sensibilidad de sus datos personales. Por
            lo tanto, nos comprometemos a compartir su información personal
            únicamente en las siguientes circunstancias:
          </p>
        </li>
        <li>
          <p>
            Con proveedores de servicios: Podemos compartir su información con
            proveedores de servicios que nos ayuden a operar nuestro servicio y
            mejorar su funcionalidad.
          </p>
        </li>
        <li>
          <p>
            Con su consentimiento: En cualquier otro caso no contemplado en esta
            política, solo compartiremos su información personal previa
            obtención de su consentimiento explícito.
          </p>
        </li>
        <li>
          <p>
            Nos comprometemos a cumplir con los requisitos de uso limitado de la
            Política de datos de usuario de los servicios API de Google y a
            proteger su privacidad y seguridad en todo momento.
          </p>
        </li>
      </ul>
      <hr />
      <h3>Conservación de sus datos personales</h3>
      <p>
        La Empresa conservará sus Datos Personales únicamente durante el tiempo
        que sea necesarios para los fines establecidos en la presente Política
        de Privacidad. La Empresa conservaremos y utilizaremos sus Datos
        Personales en la medida necesaria para cumplir con nuestras obligaciones
        legales (por ejemplo, si estamos obligados a conservar sus datos para
        cumplir con la legislación aplicable), resolver conflictos y hacer
        cumplir nuestros acuerdos legales y políticas.
      </p>
      <p>
        La Empresa también conservará los Datos de Uso con fines de análisis
        interno. Por lo general, los Datos de Uso se conservan durante un
        período de tiempo más breve, excepto cuando estos datos se utilizan para
        reforzar la seguridad o mejorar la funcionalidad de Nuestro Servicio, o
        estemos legalmente obligados a conservar estos datos durante períodos de
        tiempo más largos.
      </p>
      <hr />
      <h3>Transferencia de sus datos personales</h3>
      <p>
        u información, incluidos los Datos Personales, se procesa en las
        oficinas de la Empresa y en cualquier otro lugar donde se encuentren las
        partes implicadas en el el tratamiento. Esto significa que esta
        información puede ser transferida a - y mantenida en - ordenadores
        ubicados fuera de Su estado, provincia, país u otra jurisdicción
        gubernamental en la que la leyes de protección de datos puedan diferir
        de las de Su jurisdicción.
      </p>
      <p>
        Su consentimiento a esta Política de Privacidad seguido de Su envío de
        dicha información representa su aceptación de dicha transferencia.
      </p>
      <p>
        La Empresa tomará todas las medidas razonablemente necesarias para
        garantizar que Sus Sus datos sean tratados de forma segura y de acuerdo
        con esta Política de Privacidad y no se realizará ninguna transferencia
        de Sus Datos Personales a una organización o un país a menos que existan
        controles adecuados que incluyan la seguridad de sus datos y otra
        información personal.
      </p>
      <hr />
      <h3>Borrar sus datos personales</h3>
      <p>
        Usted tiene derecho a eliminar o solicitar que le ayudemos a eliminar
        los Datos Personales que hayamos recopilado sobre Usted.
      </p>
      <p>
        Nuestro Servicio puede ofrecerle la posibilidad de eliminar cierta
        información sobre Usted dentro del Servicio.
      </p>
      <p>
        Puede actualizar, modificar o eliminar su información en cualquier
        momento accediendo a su cuenta accediendo a su cuenta, si la tiene, y
        visitando la sección de configuración de cuenta que le permite gestionar
        su información personal. También puede También puede ponerse en contacto
        con nosotros para solicitar el acceso, la corrección o la eliminación de
        cualquier información personal que nos haya facilitado. personal que nos
        haya proporcionado.
      </p>
      <p>
        No obstante, tenga en cuenta que es posible que debamos conservar
        determinada información cuando tengamos una obligación legal o una base
        jurídica para hacerlo.
      </p>
      <hr />
      <h3>Divulgación de sus datos personales</h3>
      <p>
        En nuestro compromiso por salvaguardar su privacidad y proteger sus
        datos personales, nos comprometemos a adherirnos estrictamente a las
        siguientes prohibiciones en cuanto a la divulgación de su información:
      </p>
      <p>
        No transferiremos ni venderemos sus datos del usuario a terceros, lo que
        incluye plataformas publicitarias, corredores de datos o cualquier
        entidad que se dedique a la reventa de información.
      </p>
      <p>
        No transferiremos, venderemos ni utilizaremos sus datos de usuario con
        el propósito de publicar anuncios, ya sea a través de reorientación,
        publicidad personalizada o cualquier método basado en intereses.
      </p>
      <p>
        No transferiremos, venderemos ni utilizaremos sus datos del usuario para
        evaluar o determinar la solvencia crediticia, ni con fines relacionados
        con créditos.
      </p>
      <p>
        Estas prohibiciones son fundamentales en nuestro compromiso por respetar
        su privacidad y proteger sus datos personales de cualquier uso no
        autorizado o indebido. Nos comprometemos a garantizar que su información
        se utilice exclusivamente para los fines establecidos en nuestra
        política de privacidad y que se salvaguarden sus derechos en todo
        momento.
      </p>
      <p>
        En caso de tener alguna pregunta o inquietud respecto a la divulgación
        de sus datos personales, no dude en ponerse en contacto con nuestro
        equipo de atención al cliente, quien estará encantado de asistirle y
        proporcionarle la información que necesite.
      </p>
      <hr />
      <h4>Cumplimiento de la ley</h4>
      <p>
        En determinadas circunstancias, la Empresa puede verse obligada a
        revelar Sus Datos Personales si así lo exige la ley o en respuesta a
        solicitudes válidas solicitudes válidas de las autoridades públicas (por
        ejemplo, un tribunal o una agencia gubernamental).
      </p>
      <hr />
      <h4>Otros requisitos legales</h4>
      <p>
        La Empresa podrá revelar sus Datos Personales en la creencia de buena fe
        que dicha acción es necesaria para:
      </p>
      <ul>
        <li>Cumplir una obligación legal</li>
        <li>Proteger y defender los derechos o la propiedad de la empresa</li>
        <li>
          Prevenir o investigar posibles irregularidades en relación con el
          Servicio
        </li>
        <li>
          Proteger la seguridad personal de los usuarios del Servicio o del
          público.
        </li>
        <li>Protección frente a la responsabilidad legal</li>
      </ul>
      <hr />
      <h3>Seguridad de sus datos personales</h3>
      <p>
        La seguridad de sus datos personales es importante para nosotros, pero
        recuerde que ningún método de transmisión a través de Internet, o método
        de almacenamiento electrónico es 100% seguro. Aunque nos esforzamos por
        utilizar medios comercialmente aceptables para proteger sus datos
        personales, no podemos garantizar su absoluta seguridad absoluta.
      </p>
      <hr />
      <h2>Privacidad infantil</h2>
      <p>
        Nuestro Servicio no se dirige a menores de 13 años. No recopilamos
        recopilamos conscientemente información de identificación personal de
        menores de 13 años. Si usted es padre o tutor y sabe que su hijo nos ha
        proporcionado datos personales, póngase en contacto con nosotros. Si
        tenemos conocimiento de que hemos recopilado datos personales de menores
        de 13 años sin verificar la identidad de sus padres, le rogamos que se
        ponga en contacto con nosotros. menores de 13 años sin el consentimiento
        paterno, tomaremos las medidas necesarias para eliminar esa información
        de nuestros servidores.
      </p>
      <p>
        Si necesitamos basarnos en el consentimiento como base legal para
        procesar su información y su país requiere el consentimiento de uno de
        los padres, podremos consentimiento de sus padres antes de recopilar y
        utilizar dicha información.
      </p>
      <hr />
      <h2>Enlaces a otros sitios web</h2>
      <p>
        Nuestro Servicio puede contener enlaces a otros sitios web que no son
        operados por nosotros. Si hace clic en un enlace de terceros, se le
        dirigirá al sitio de ese tercero. Le recomendamos encarecidamente que
        consulte la Política de privacidad de cada sitio que visite.
      </p>
      <p>
        No tenemos ningún control ni asumimos ninguna responsabilidad por el
        contenido, las políticas de privacidad o las prácticas de los sitios o
        servicios de terceros.
      </p>
      <hr />
      <h2>Cambios en esta política de privacidad</h2>
      <p>
        Es posible que actualicemos Nuestra Política de Privacidad de vez en
        cuando. Le notificaremos de cualquier cambio mediante la publicación de
        la nueva Política de Privacidad en esta página.
      </p>
      <p>
        Se lo comunicaremos por correo electrónico y/o mediante un aviso
        destacado en Nuestro Servicio, antes de que el cambio sea efectivo y
        actualizaremos la fecha de &quot;Última actualizado&quot; en la parte
        superior de esta Política de Privacidad.
      </p>
      <p>
        Le aconsejamos que revise periódicamente esta Política de Privacidad
        para comprobar si se han producido cambios. cambios. Las modificaciones
        de esta Política de Privacidad entrarán en vigor cuando se publiquen en
        esta página. publicadas en esta página.
      </p>
      <hr />
      <h2>Póngase en contacto con nosotros</h2>
      <p>
        Si tiene alguna pregunta sobre esta Política de privacidad, puede
        ponerse en contacto con nosotros:
      </p>
      <ul>
        <li>
          <p>Por correo electrónico: info@vitalmove.cl</p>
        </li>
        <li>
          <p>
            Visitando esta página de nuestro sitio web:{" "}
            <a
              href="https://www.vitalmove.cl"
              rel="external nofollow noopener noreferrer"
              target="_blank"
            >
              www.vitalmove.cl
            </a>
          </p>
        </li>
      </ul>
    </>
  );
};

export default Privacity;
