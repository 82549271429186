import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Welcome from "./pages/Welcome";
import AOS from "aos";
import "aos/dist/aos.css";
import { useState } from "react";
import { useEffect } from "react";
import Loading from "./components/Loading";

function App() {
  AOS.init();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setTimeout((e) => {
      setIsLoading(true);
    }, 2500);
  }, []);
  return <div className="App">{isLoading ? <Welcome /> : <Loading />}</div>;
}

export default App;
