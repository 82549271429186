import React from "react";
import "../styles/Vmapp.css";
import androidAppVM from "../assets/vitalMoveFunctional.webp";
import dailyActivity from "../assets/activity.png";
import training from "../assets/training01.png";
import nutrition from "../assets/manzana.png";
import vmClass from "../assets/auricular.png";
import androidAppVMResponsive from "../assets/vitalMoveappResponsive.webp";

export default function VmApp() {
  const arrayItems = [
    {
      id: "01",
      src: dailyActivity,
      alt: "actividad-logo",
      tittle: "Actividad Diaria",
      text: "Selecciona una de las diferentes actividades, pesas, ciclismo, yoga y muchas más. ",
    },
    {
      id: "02",
      src: training,
      alt: "entrenamiento-logo",
      tittle: "Entrenamiento",
      text: "Inicia tu entrenamiento y realiza el seguimiento desde el día 1 para ver todos tus avances.",
    },
    {
      id: "03",
      src: nutrition,
      alt: "nutricion-logo",
      tittle: "Nutrición",
      text: "Selecciona tu alimentación para cada momento del día, desayuno, almuerzo, colación o cena.  ",
    },
    {
      id: "04",
      src: vmClass,
      alt: "vmClass-logo",
      tittle: "VM Class",
      text: "Desde VM Class, tendrás acceso a tus horarios, recursos, calificaciones y mucho más.",
    },
  ];
  return (
    <div className="py-5 div-container-vm">
      <div className="div-container-vm-app">
        <div className="div-tittle-vm-app">
          <h2 className="tittle-vm-app">Monitorea todas tus actividades</h2>
          <p className="text-tittle-vm-app">
            Desde tu App <strong>VitalMove</strong> vas a poder ingresar a
            diferentes secciones, y visualizar estadísticas, tus actividades y
            las VM Class.{" "}
          </p>
        </div>
        <div
          className="div-vm-app"
          data-aos="flip-left"
          data-aos-offset="200"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-mirror="true"
          data-aos-once="true"
        >
          {arrayItems.map((e) => {
            return (
              <div
                className="div-card-vm shadow p-3 bg-body rounded"
                key={e.id}
              >
                <img className="img-vm-app" src={e.src} alt={e.alt} />
                <div className="div-text-vm-app">
                  <h2>{e.tittle}</h2>
                  <p> {e.text} </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <img
        src={androidAppVM}
        alt="android vitalmove app"
        className="android-app-vm"
        data-aos="flip-right"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="1000"
        data-aos-mirror="true"
        data-aos-once="true"
      />
      <img
        src={androidAppVMResponsive}
        alt="android vitalmove app"
        className="app-responsive"
      />
    </div>
  );
}
