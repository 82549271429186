import React from "react";
import "../styles/Store.css";
import storePhones from "../assets/storePhones.webp";
import appStoreLogo from "../assets/app-store.png";
import playStoreLogo from "../assets/Androidpng.png";
import logoTitle from "../assets/tittleVM.svg";

export default function Store() {
  return (
    <div className="div-store container">
      <div
        className="div-download-app shadow p-5 bg-body rounded"
        data-aos="zoom-in-down"
        data-aos-offset="200"
        data-aos-delay="70"
        data-aos-duration="1000"
        data-aos-mirror="true"
        data-aos-once="true"
      >
        <img src={logoTitle} alt="logo-black-VM" className="logo-black-vm" />
        <h2>Ya disponible en Android!</h2>
        {/* <p>Descarga Ya</p> */}
        <div className="div-logo-stores">
          <img
            className="logo-stores"
            src={appStoreLogo}
            alt="app Store Logo"
          />
          <a rel="noreferrer" target="_blank" href="https://play.google.com/store/apps/details?id=com.vm_mobile&pcampaignid=web_share">
          <img
            className="logo-stores"
            src={playStoreLogo}
            alt="play Store Logo"
          />
          </a>
        </div>
      </div>
      <div
        data-aos="zoom-in-down"
        data-aos-offset="200"
        data-aos-delay="70"
        data-aos-duration="1000"
        data-aos-mirror="true"
        data-aos-once="true"
      >
        <img
          src={storePhones}
          alt="android phone"
          className="img-store-phone"
        />
      </div>
    </div>
  );
}
